@use '@sparbanken-syd/sparbanken-syd-theme/theme' as spb;
@include spb.set-typography;


// Remove annoying 5px margin
body, html {
  margin: 0;
}

// We cannot, ever, have blue links.
a, a:visited {
  color: #232323;
}


/* Table styles */
table {
  caption {
    display: none;
  }

  th {
    color: rgb(0 0 0 / 54%) !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    white-space: nowrap !important;
  }

  th.align-header-right {
    .mat-sort-header-container {
      justify-content: flex-end !important;
    }
  }

  td.align-cell-right {
    text-align: right;
    padding-right: 34px; // Original is 16px + 18px more (sorting size)
  }

  .no-data {
    color: #696969;
    font-size: 24px;
    padding: 25px;
    text-align: center;
  }

  .sub-text {
    color: #939393;
    font-size: 15px;
  }
}